import RestApi, { seedFertilizerServiceBaseUrl } from '../config/api_config'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      hasDropdownLoadedSeedsFertilizer: 'SeedsFertilizer/hasDropdownLoaded'
    }),
    fiscalYearList () {
      return this.$store.state.commonObj.fiscalYearList
    }
  },
  watch: {
    hasDropdownLoadedSeedsFertilizer: function (newValue) {
      if (!newValue) {
        this.loadDropdownSeedsFertilizer()
      }
    },
    fiscalYearList (newValue) {
      this.setCurrentFiscalYear(newValue)
    }
  },
  mounted () {
    // this.setCurrentFiscalYear()
  },
  created () {
    if (!this.hasDropdownLoadedSeedsFertilizer || window.performance) {
      this.loadDropdownSeedsFertilizer()
    }
  },
  methods: {
    loadDropdownSeedsFertilizer () {
      RestApi.getData(seedFertilizerServiceBaseUrl, 'common-dropdowns', null).then(response => {
        if (response.success) {
            this.$store.commit('SeedsFertilizer/mutateCommonObj', {
                hasDropdownLoaded: true,
                programTypeList: response.data.programTypeList,
                carryoverList: response.data.carryoverList,
                portZoneSetupList: response.data.portZoneSetupList,
                CropTypeList: response.data.CropTypeList,
                CropNameList: response.data.CropNameList,
                VarietyList: response.data.VarietyList,
                fertilizerImportCountryList: response.data.fertilizerImportCountryList,
                salesCenterList: response.data.salesCenterList,
                cnfAgentList: response.data.cnfAgentList,
                transportAgentList: response.data.transportAgentList,
                seedClassList: response.data.seedClassList,
                productionSourceList: response.data.productionSourceList,
                measurementUnitList: response.data.measurementUnitList,
                productionSeasonList: response.data.productionSeasonList,
                fertilizerTypeList: response.data.fertilizerTypeList,
                fertilizerNameList: response.data.fertilizerNameList,
                portInfoSetupList: response.data.portInfoSetupList,
                godownInfoList: response.data.godownInfoList,
                allocationTypeList: response.data.allocationTypeList,
                organizationList: response.data.organizationList,
                priceTypeList: response.data.priceTypeList,
                packetVarietyList: response.data.packetVarietyList,
                zoneOfficeList: response.data.zoneOfficeList,
                spcOfficeList: response.data.spcOfficeList,
                prerequisiteChecklist: response.data.prerequisiteChecklist,
                gpOrganizationsList: response.data.gpOrganizationList,
                dealerBasicList: response.data.dealerBasicList,
                gpCropTypeList: response.data.gpCropTypeList,
                gpCropNameList: response.data.gpCropNameList,
                gpConservationTypeList: response.data.gpConservationTypeList,
                gpUnitList: response.data.gpUnitList,
                collectionSourceList: response.data.collectionSourceList,
                characterizationTypeList: response.data.characterizationTypeList,
                descriptorLabelList: response.data.descriptorLabelList,
                descriptorHeadingList: response.data.descriptorHeadingList,
                codeGuideDetail: response.data.codeGuideDetail,
                dealerBasicInfoList: response.data.dealerBasicInfoList,
                seedProductionSourceTypeList: response.data.seedProductionSourceTypeList,
                seedCategoryList: response.data.seedCategoryList,
                seedSubTypeList: response.data.seedSubTypeList
          })
          this.$store.commit('SeedsFertilizer/localizeDropdown', { value: this.$i18n.locale })
        }
      })
    },
    setCurrentFiscalYear (fiscalYearList) {
      if (fiscalYearList.length === 0) {
        return
      }
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
      /** Define the year position  */
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      let currentFiscalYearId = 0
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          currentFiscalYearId = element.value
        }
      })
      this.$store.dispatch('SeedsFertilizer/setCurrentFiscalYearId', currentFiscalYearId)
    }
  }
}
